import i18n from '@/language';
import cookie from 'js-cookie';
import { throttle } from 'lodash';
import TCAPI from '~/api-tc';

const state = {
  language: '',
  isGameView: false,
  version: '0.0.1',
  LocationID: 0,
  LocationCode: 'th',
  IsLoading: false,
  isBGMEnable: true,
  prevPage: null,
  favGames: [],
  specialOfferList: [],
  isAlertShow: false,
  isMaintainence: false,
  isOpenShop: false,
  MaintainContent: '',
  Domains: {},
  ProviderList: [],
  SocketVersion: '',
  GameFilter: {
    gameType: null,
    provider: 'top50',
    providerId: null,
  },
  isOpenGameWallet: false,
  drawer: {
    show: false,
    from: '',
    title: '',
  },
  chatSetting: {
    font: 'medium',
    opacity: 50,
    showMsgInGame: true,
  },
};

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language;
  },
  SET_VERSION: (state, version) => {
    state.version = version;
  },
  SET_GAMEVIEW: (state, isGameView) => {
    state.isGameView = isGameView;
  },
  SET_LOCATIONID: (state, LocationID) => {
    state.LocationID = LocationID;
  },
  SET_ISLOADING: (state, IsLoading) => {
    state.IsLoading = IsLoading;
  },
  SET_ISBGMENABLE: (state, enable) => {
    state.isBGMEnable = enable;
  },
  SET_IS_OPENSHOP: (state, enable) => {
    state.isOpenShop = enable;
  },
  SET_PREVPAGE: (state, page) => {
    state.prevPage = page;
  },
  SET_FAVGAMES: (state, game) => {
    state.favGames = game;
  },
  SET_SPECIALOFFERLIST: (state, list) => {
    state.specialOfferList = list;
  },
  SET_ISALERTSHOW: (state, bool) => {
    state.isAlertShow = bool;
  },
  SET_ISMAINTAINENCE: (state, bool) => {
    state.isMaintainence = bool;
  },
  SET_MAINTAINCONTENT: (state, text) => {
    state.MaintainContent = text;
  },
  SET_DOMAINS: (state, data) => {
    state.Domains = data;
  },
  SET_PROVIDERLIST: (state, data) => {
    state.ProviderList = data;
  },
  SET_SOCKETVERSION: (state, data) => {
    state.SocketVersion = data;
  },
  SET_GAMEFILTER: (state, data) => {
    state.GameFilter = data;
  },
  SET_ISOPENGAMEWALLET: (state, data) => {
    state.isOpenGameWallet = data;
  },
  SET_LOCATIONCODE: (state, data) => {
    state.LocationCode = data;
  },
  SET_DEFERRED_PROMPT: (state, data) => {
    state.drawer.deferredPrompt = data;
  },
  SET_DRAWER: (state, { show, from }) => {
    state.drawer.show = show;
    if (!show) return;

    state.drawer.from = from;
    state.drawer.title = from === '401' ? '' : process.env.VUE_APP_TITLE;
  },
  SET_CHATSETTING: (state, data) => {
    state.chatSetting = data;
  },
};

const actions = {
  setLanguage({ commit, dispatch }, language) {
    commit('SET_LANGUAGE', language);
    cookie.set('lang', language);
    dispatch('setLanguagePatch');
  },
  async setLanguagePatch({ state }, payload) {
    const lang = state.language;
    const res = await TCAPI.base.getLanguageData({ lang }).catch((error) => {
      console.log(error);
    });

    if (!res?.isSuccess) return;
    localStorage.setItem('langData', JSON.stringify(res.data));
    i18n.mergeLocaleMessage(lang, res.data);
  },
  setGameView({ commit }, isGameView) {
    commit('SET_GAMEVIEW', isGameView);
  },
  setVersion({ commit }, version) {
    commit('SET_VERSION', version);
  },
  setLocationID({ commit }, LocationID) {
    commit('SET_LOCATIONID', LocationID);
  },
  setLoading({ commit }, isLoading) {
    commit('SET_ISLOADING', isLoading);
  },
  setBGMEnable({ commit }, enable) {
    commit('SET_ISBGMENABLE', enable);
  },
  setPrevPage({ commit }, page) {
    commit('SET_PREVPAGE', page);
  },
  setFavGame({ commit }, game) {
    commit('SET_FAVGAMES', game);
  },
  setSpecialOfferList({ commit }, list) {
    commit('SET_SPECIALOFFERLIST', list);
  },
  setIsAlertShow({ commit }, bool) {
    commit('SET_ISALERTSHOW', bool);
  },
  setIsMaintainence({ commit }, bool) {
    commit('SET_ISMAINTAINENCE', bool);
  },
  setMaintainContent({ commit }, text) {
    commit('SET_MAINTAINCONTENT', text);
  },
  setDomains({ commit }, data) {
    commit('SET_DOMAINS', data);
  },
  setProviderList({ commit }, data) {
    commit('SET_PROVIDERLIST', data);
  },
  setSocketVersion({ commit }, data) {
    commit('SET_SOCKETVERSION', data);
  },
  setGameFilter({ commit }, data) {
    commit('SET_GAMEFILTER', data);
  },
  setIsOpenGameWallet({ commit }, data) {
    commit('SET_ISOPENGAMEWALLET', data);
  },
  setLocationCode({ commit }, data) {
    commit('SET_LOCATIONCODE', data);
  },
  setDrawer({ commit }, data) {
    commit('SET_DRAWER', data);
  },
  setChatSetting({ commit }, data) {
    commit('SET_CHATSETTING', data);
  },
  fetchLobbyInfo: throttle(({ dispatch, rootState }) => {
    // 錢包餘額
    dispatch('financial/actionQueryAllPlatMoney', null, { root: true });
    // 贈禮
    dispatch(
      'give/actionGetGiftList',
      { startIndex: 0, pageSize: 10 },
      { root: true },
    );
    // 抽獎券
    dispatch('promotion/actionGetLotteryTicket', undefined, { root: true });
    // 系統信
    dispatch('mailBox/actionGetAllMailBox', null, { root: true });
    dispatch('mailBox/actionGetBonusList', {
      limit: {
        pageSize: 10,
        startIndex: 0,
      },
      sorts: [
        {
          columnName: 'addTime',
          orderType: 'desc',
        },
      ],
    }, { root: true });
    // 每日任務
    if (rootState.initData.dailyMissionControl?.visible) {
      dispatch('mission/actionMissionList', null, { root: true });
    }
    if (rootState.initData.dailyMissionControl?.activityVisible) {
      dispatch('mission/actionActivityData', null, { root: true });
    }
    // 會員 bet level
    dispatch('member/actionGetMemberBetLevel', null, { root: true });
    dispatch('actionGetMaintainInfo', null, { root: true });
    // 好友 - 申請列表
    dispatch(
      'friend/actionGetFriendRequestList',
      { limit: { pageSize: 10, startIndex: 0 } },
      { root: true },
    );
  }, 5_000),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
