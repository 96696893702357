import { getShopList } from '~/api-tc/buy';

const state = {
  shopList: [],
  isGooglePayAvailable: false,
  isApplePayAvailable: false,
};

const mutations = {
  SET_SHOPLIST: (state, data) => {
    state.shopList = data;
  },
  SET_IS_GOOGLE_PAY_AVALIABLE: (state, data) => {
    state.isGooglePayAvailable = data;
  },
  SET_IS_APPLE_PAY_AVALIABLE: (state, data) => {
    state.isApplePayAvailable = data;
  },
};

const actions = {
  actionGetShopList: async ({ commit }, data) => {
    const res = await getShopList(data);
    if (res?.isSuccess) {
      commit('SET_SHOPLIST', res.data);
    }
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
